<template>
	<div>
		<CRow>
			<CCol class="text-right mb-3">
				<CButton
					class="transparent"
					color="secondary"
					data-test-id="add-attribute-btn"
					@click="$refs['modal-add'].open()"
				>
					<CIcon class="icon-left" name="cil-plus" />
					Create attribute set
				</CButton>
			</CCol>
		</CRow>
		<CRow>
			<CCol>
				<form class="search-form" @submit.prevent="handleSearch">
					<CInput
						v-model.trim="queryParams.q"
						data-test-id="attribute-sets-search"
						type="text"
						placeholder="Search by attribute set name or set ID"
					>
						<template #prepend-content>
							<button
								type="submit"
								class="btn-search"
							>
								<FontAwesomeIcon :icon="['far', 'search']" />
							</button>
						</template>
					</CInput>
				</form>
			</CCol>
		</CRow>
		<CRow class="mb-4">
			<CCol>
				<BaseSearchFormFooter
					:count="list.meta.total"
					append-text="attribute keys found"
					reset-filter-btn-text="Reset search"
					@onReset="resetFilter"
				/>
			</CCol>
		</CRow>
		<BaseTable
			:is-loading="list.isLoading"
			:fields="FIELDS"
			:items="attributeSets"
			:pagination="{
				pages: list.meta.lastPage,
				activePage: list.meta.currentPage
			}"
			striped
			clickable-rows
			link-to="AttributeSetEdit"
			class="table-custom table-custom-link"
			data-test-id="attribute-sets-table"
			@onPaginationClick="handlePageChange"
		>
			<template #no-items-view>
				<div class="empty-table-element">
					<p class="subtitle">
						{{ $t("global.searchNotFound", { field: "attribute set" }) }}
					</p>
				</div>
			</template>
			<template #name="{item}">
				<CRow data-test-id="attribute-sets-name">
					<CCol>{{ item }}</CCol>
					<CCol class="text-right">
						<FontAwesomeIcon
							:icon="['fas', 'chevron-right']"
							class="icon-next mr-1"
						/>
					</CCol>
				</CRow>
			</template>
		</BaseTable>
		<ModalCreateAttribute
			ref="modal-add"
			:handle-create="createAttributeSet"
			title="Create new attribute set"
			@onSuccess="handleCreateSuccess"
		/>
	</div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import { cleanObject, randomSearchString } from '../assets/js/helpers';
import ModalCreateAttribute from '../components/ModalCreateAttribute.vue';

const FIELDS = [
	{ key: 'id', label: 'ID', class: 'col-id' },
	{ key: 'name', label: 'Attribute sets', class: 'col-name' },
];

export default {
	components: {
		ModalCreateAttribute,
	},
	data() {
		return {
			FIELDS,
			queryParams: {
				q: this.$route.query.q || null,
				page: Number(this.$route.query.page) || null,
				r: randomSearchString(),
			},
		};
	},
	computed: {
		...mapState('attributeSets', {
			list: 'list',
			create: 'create',
		}),
		...mapGetters({
			attributeSets: 'attributeSets/attributeList',
		}),
	},
	created() {
		this.getAttributeSets(this.queryParams);
	},
	methods: {
		...mapActions({
			getAttributeSets: 'attributeSets/getAttributeSets',
			createAttributeSet: 'attributeSets/createAttributeSet',
		}),
		updateUrlParams() {
			const query = cleanObject(JSON.parse(JSON.stringify(this.queryParams)));
			this.$router.push({ query }).catch(() => {});
		},
		handleSearch() {
			this.queryParams.page = null;
			this.updateUrlParams();
		},
		handlePageChange(page) {
			this.queryParams.page = page;
			this.updateUrlParams();
		},
		resetFilter() {
			this.queryParams = {
				q: null,
				page: null,
				r: null,
			};

			this.updateUrlParams();
		},
		async handleCreateSuccess() {
			this.queryParams.page = null;
			this.queryParams.q = '';
			await this.getAttributeSets(this.queryParams);
		},
	},
};
</script>

<style lang="scss" scoped>
	.btn-wrapper {
		min-width: rem(192);
	}

	.btn-search {
		background: transparent;
		box-shadow: none;
		border: none;
		padding: 0;
	}

	.icon-next {
		opacity: 0.5;
		width: rem(12);
		height: rem(12);
	}

	::v-deep .col-name {
		z-index: 1;
	}
</style>
